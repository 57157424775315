import React, { Fragment, useState, useEffect } from 'react';

import { connect } from "react-redux";
import { Card, Table, Input, Button, Divider, Form, Row, Col, Select, notification, Drawer } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { ACCESS_TOKEN, API_BASE_URL } from 'constant';
import axios from 'axios';
import UpdateForm from './UpdateForm'

const key = 'updatable';

function UserList(props) {
    const [state, setState] = useState({
        users: [],
        isLoading: false,
        open: false,
        listRole: [],
        roleLoad: false,
        data: {},
        visible: false,
        currentData: {},
        loading: false,
        listCompagnie: [],
        isUpdateDrawerVisible: false,
        listCode: [],
        suppressionId: null

    })

    useEffect(() => {
        if(!state.isUpdateDrawerVisible) {
            getAllCodeCompagnie()
        }
        
    }, [state.isUpdateDrawerVisible])


    // Notification
    const openNotificationWithIcon = (type, title, msg) => {
        notification[type]({
            message: title,
            description: msg,
        });
    };

    const onFinish = (value) => {
        const token = localStorage.getItem(ACCESS_TOKEN);

        var config = {
            method: 'post',
            url: API_BASE_URL + '/compagnie',
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
            data: value,
        };
        axios(config)
            .then(response => {
                console.log("reponse create user: ", response.data)
                setState(state => ({ ...state, response: state.listCompagnie }))
                openNotificationWithIcon('success', 'Succès', 'Engeristrement effectué avec succès !!!');
                hideAddModal()
                onClose()
                getAllCodeCompagnie()
                window.location.reload()
            })
            .catch(function (error) {
                console.log("error save user ==============>>>>>", error.response)
                openNotificationWithIcon('error', 'Erreur', error.response.data?.message);
                console.log(error);
            });
    }

    const getAllCodeCompagnie = values => {

        const token = localStorage.getItem(ACCESS_TOKEN);
        var config = {
            method: 'get',
            url: API_BASE_URL + '/compagnie',
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
        };
        axios(config)
            .then(response => {
                setState(state => ({
                    ...state,
                    listCompagnie: response.data.data,
                    roleLoad: true,
                }))
                // console.log(1111111111111111111111122222222222, response)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function handleCloseUpdate() {
        setState(state => ({ ...state, isUpdateDrawerVisible: false }))
    }

    // Delete
    const selectForUpdate = item => {
        console.log("item ", item)
        form.resetFields();
        setState(state => ({
            ...state,
            updateLigne: item,
            openModalForUpd: true
        }))
    };

    const deleteLine = () => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        var config = {
            method: 'delete',
            url: API_BASE_URL + '/compagnie/' + state.suppressionId,
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
        };

        axios(config)
            .then(response => {
                openNotificationWithIcon('success', 'Succès', 'Suppression effectuée avec succès !!!');
                getAllCodeCompagnie();
            })
            .catch(function (error) {
                openNotificationWithIcon('error', 'Erreur', error.response.data.data);
                console.log(error);
            });
    }

    const columns = [
        {
            title: 'Code compagnie',
            dataIndex: 'codeCompagnie',
            key: 'codeCompagnie',
        },
        {
            title: 'Nom compagnie',
            dataIndex: 'nomCompagnie',
            key: 'nomCompagnie',
        },
        {
            key: 'actions',
            title: "Actions",
            render: (text, item) => (
                <Fragment style={{ float: 'right' }}>
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        size="smail"
                        onClick={() => setState(state => ({ ...state, currentItem: item, isUpdateDrawerVisible: true }))}
                        style={{ margin: 10 }}
                    />
                    {/* <Button
                        type="primary"
                        icon={<DeleteOutlined />}
                        size="smail"
                        onClick={() => setState(state => ({ ...state, currentItem: item, isUpdateDrawerVisible: true }))}
                    /> */}

                </Fragment>
            )
        },
    ];

    const { Option } = Select;

    function hideAddModal() {
        setState(state => ({ ...state, open: false }))
    }

    const [form] = Form.useForm();

    const showDrawer = (compagnie) => {
        setState(state => ({
            ...state,
            visible: true,
            currentData: compagnie
        }));
    };

    const onClose = () => {
        setState(state => ({
            ...state,
            visible: false,
        }));
    };


    return (
        <Fragment>
            <Card>
                <div className="container-fluid">
                    <div style={{ display: 'inline-block', float: 'left' }}>
                        <Button type="primary" onClick={showDrawer}>
                            <PlusOutlined /> Ajouter une compagnie
                        </Button>
                    </div><br />
                </div>

                <Divider />

                <div style={{ overflow: "auto", padding: 5 }}>
                    <Table
                        columns={columns}
                        dataSource={state.listCompagnie}
                    />
                </div>
            </Card>

            {state.isUpdateDrawerVisible && (
                <UpdateForm
                    visible={state.isUpdateDrawerVisible}
                    currentItem={state.currentItem}
                    close={handleCloseUpdate}

                />
            )}

            <Drawer
                width={900}
                title="Ajouter une compagnie"
                //  width={colSize == "xs" ? 300 : colSize == "sm" ? 400 : 900}
                placement="right"
                closable={false}
                //onClose={onClose}
                visible={state.visible}
            >
                <Form layout="vertical" hideRequiredMark form={form} name="control-hooks" onFinish={onFinish}>
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <Form.Item
                                name="codeCompagnie"
                                id="codeCompagnie"
                                label="Code compagnie"
                                rules={[{ required: true, message: "Veuillez selectionner le code compagnie" }]}
                            >
                                <Input placeholder="Veuillez saisir le code de la compagnie" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="nomCompagnie"
                                id="nomCompagnie"
                                label="Nom compagnie"
                                rules={[{ required: true, message: "Veuillez saisir le nom de la compagnie" }]}
                            >
                                <Input placeholder="Veuillez saisir le nom de la compagnie" />
                            </Form.Item>
                        </Col>
                    </Row><br /><br />

                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 16px',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
                            Fermer
                        </Button>,
                        <Button
                            disabled={state.loading}
                            loading={state.loading}
                            htmlType="submit" type="primary"
                        >
                            Enregistrer
                        </Button>
                    </div>
                </Form>
            </Drawer>

            {/* ================================= Update =========================================== */}

            {/* <Drawer
                title={`${currentItem.username}`}
                width={colSize == "xs" ? 300 : colSize == "sm" ? 400 : 720}
                onClose={() => handleCloseUpdate()}
                visible={props.visible}
                bodyStyle={{ paddingBottom: 80}}
            >

                <Form layout="vertical" hideRequiredMark form={form} name="control-hooks" onFinish={onFinish}>
               

                <Row gutter={16}>
                    <Col span={24}>
                    <Form.Item
                        name="codeCompagnie"
                        label="Code compagnie"
                        rules={[{ required: true, message: 'Veuillez saisir le code' }]}
                        initialValue={currentItem.codeCompagnie}
                    >
                        <Input placeholder="Veuillez saisir le code compagnie" />
                    </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                    <Form.Item
                        name="nomPrenom"
                        label="Nom compagnie"
                        rules={[{ required: true, message: 'Veuillez saisir le login' }]}
                        initialValue={currentItem.nomPrenom}
                    >
                        <Input placeholder="Veuillez saisir le login" />
                    </Form.Item>
                    </Col>
                    <Col span={12}>
                    <Form.Item
                        name="username"
                        label="Nom utilisateur"
                        rules={[{ required: true, message: 'Veuillez saisir le Nom utilisateur' }]}
                        initialValue={currentItem.username}
                    >
                        <Input placeholder="Veuillez saisir le login" />
                    </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="phone"
                        label="Téléphone"
                        rules={[{ required: true, message: 'Veuillez saisir le téléphone' }]}
                        initialValue={currentItem.phone}
                    >
                        <Input
                        style={{ width: '100%' }}
                        addonBefore="+225"
                        placeholder="Veuillez saisir le téléphone"
                        />
                    </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                        { required: true, message: "Veuillez saisir l'email" },
                        {type: 'email', message: "Votre email valide"},
                        ]}
                        initialValue={currentItem.email}
                    >
                        <Input placeholder="Veuillez saisir l'email" />
                    </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                    <Form.Item
                        name="raisonSocial"
                        label="Raison sociale"
                        rules={[{ required: true, message: 'Veuillez saisir la raison sociale' }]}
                        initialValue={currentItem.raisonSocial}
                    >
                        <Input placeholder="Veuillez saisir la raison sociale" />
                    </Form.Item>
                    </Col>
                    <Col span={12}>
                    <Form.Item
                        name="roleId"
                        label="Role"
                        rules={[{ required: true, message: 'Veuillez selectionner le role' }]}
                        initialValue={currentItem.name}
                    >
                        <Select placeholder="Veuillez selectionner le role">
                        {state.roles.map(( m, index) => (
                            <Option key={m.id} value={m.id}>{m.name}</Option>
                        ))}
                        </Select>
                    </Form.Item>
                    </Col>
                </Row>

                <div
                    style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    }}
                >
                    <Button onClick={() => handleClose()} style={{ marginRight: 8 }}>
                    Annuler
                    </Button>
                    <Button   
                    loading={state.loading} 
                    htmlType="submit" type="primary"
                    >
                    Enregistrer
                    </Button>
                </div>
                </Form>
            </Drawer> */}

        </Fragment>
    )
}

const styles = {
    itemContainer: {
        margin: 7,
        fontSize: 15
    },
    itemContainerInfos: {
        margin: 5,
        fontSize: 15,
        background: "#ececec",
        padding: 7,
    },
    itemValue: {
        fontWeight: 'bold',
    }
}


export default connect()(UserList);